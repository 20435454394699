
import { defineComponent } from "vue";

export default defineComponent({
	name: "FileUpload",
	data() {
		return {
			isDragOver: false
		};
	},
	methods: {
		dragEnter() {
			this.isDragOver = true;
		},
		dragLeave() {
			this.isDragOver = false;
		},

		dropFile(e: DragEvent) {
			if (!e.dataTransfer)
				return;

			if (e.dataTransfer.files.length !== 1) {
				console.warn("Need 1 file");
				return;
			}
			const item = e.dataTransfer.files.item(0)!;
			this.$emit("upload", item);
		}
	}
});

