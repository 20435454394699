
import { defineComponent } from "vue";
import api, { V1VotingSystemsResponse } from "@/lib/api";
import state from "@/lib/state";

interface Model {
	votingSystems: V1VotingSystemsResponse;
	name: string;
	votingSystem: string;
	responseSheetLink: string;
	prefilledLink: string;
	errorMessage: string;
}

export default defineComponent({
	name: "CreateVote",
	props: [],
	data(): Model {
		return {
			votingSystems: [],
			prefilledLink: "",
			responseSheetLink: "",
			name: "",
			votingSystem: "",
			errorMessage: ""
		};
	},
	methods: {
		async createVote() {

			if ([this.responseSheetLink, this.prefilledLink, this.votingSystem, this.name].filter(value => value == "").length > 0) {
				this.errorMessage = "All fields must not be empty";
				return;
			}

			const newVote = await api.createVote({
				name: this.name,
				votingSystem: this.votingSystem,
				responseSheetLink: this.responseSheetLink,
				prefilledLink: this.prefilledLink
			}, await state.getEventId());

			await this.$router.push(`/vote/${newVote.id}`);
		}
	},
	async mounted() {
		this.votingSystems = await api.votingSystems();
		this.votingSystem = this.votingSystems[0].id;
	}
});
