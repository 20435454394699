import api from "@/lib/api";

export interface State {
	setEventId(id: string): Promise<void>;

	getEventId(): Promise<string>;
}

export type EventIdChangedEventHandler = (newId: string) => (Promise<void> | void);

class StateImpl implements State {

	async setEventId(uuid: string): Promise<void> {
		window.localStorage.setItem("event", `${uuid}`);
		for (const handler of Object.values(this.handlers))
			await handler(uuid);
	}

	async getEventId(): Promise<string> {
		const eventId = window.localStorage.getItem("event") as string;
		if (!eventId) {
			const eventResponse = await api.recentEvent();
			await this.setEventId(eventResponse.id);
			return this.getEventId();
		} else
			return eventId;
	}

	private nextHandlerId = 0;
	private handlers: { [id: number]: EventIdChangedEventHandler } = {};

	onEventChange(handler: EventIdChangedEventHandler): number {
		const nextID = this.nextHandlerId++;
		this.handlers[nextID] = handler;
		return nextID;
	}

	offEventChange(handlerId: number) {
		delete this.handlers[handlerId];
	}
}

export default new StateImpl();
