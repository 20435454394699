
import { defineComponent } from "vue";
import api, { V1VotingSystemsResponse } from "@/lib/api";

export default defineComponent({
	name: "VotingSystems",
	data() {
		return {
			systems: [] as V1VotingSystemsResponse
		};
	},
	async mounted() {
		this.systems = await api.votingSystems()
			.then(value => value.sort((a, b) => a.name.localeCompare(b.name)));
	},
	methods: {
		async createNew() {
			this.systems.push(await api.votingSystemCreate());
			this.systems = this.systems.sort((a, b) => a.name.localeCompare(b.name));

		}
	}
});
