
import { defineComponent } from "vue";
import api, { V1VoteResponse, V1VotingSystemsResponse, VoteResult } from "@/lib/api";
import FileUpload from "@/components/FileUpload.vue";
import { readUploadedFile } from "@/lib/helpers";
import VoteResultRender from "@/components/VoteResultRender.vue";

interface Model {
	vote?: V1VoteResponse;
	tokenFile?: File;
	votingSystems: V1VotingSystemsResponse;
	responseCount: number;
	results: VoteResult[];
}

export default defineComponent({
	name: "Vote",
	props: [],
	components: {
		FileUpload,
		VoteResultRender
	},
	data(): Model {
		return {
			responseCount: 0,
			tokenFile: undefined,
			votingSystems: [],
			vote: undefined,
			results: []
		};
	},
	methods: {
		openSpreadsheetLink() {
			window.open(this.vote!.spreadsheetLink);
		},
		copySpreadsheetLink() {
			navigator.clipboard.writeText(this.vote!.spreadsheetLink);
		},
		copyName() {
			navigator.clipboard.writeText(this.vote!.name);
		},
		openPrefilledLink() {
			window.open(this.vote!.prefilledLink);
		},
		copyPrefilledLink() {
			navigator.clipboard.writeText(this.vote!.prefilledLink);
		},
		async uploadTokenFile(e: File) {
			this.tokenFile = e;

			this.vote!.tokens = await readUploadedFile(e);
		},
		clearTokens() {
			this.tokenFile = undefined;
			this.vote!.tokens = [];
		},
		async saveChanges() {
			this.vote = await api.updateVote(this.vote!.id, {
				name: this.vote!.name,
				prefilledLink: this.vote!.prefilledLink,
				spreadsheetLink: this.vote!.spreadsheetLink,
				tokens: this.vote!.tokens ?? [],
				votingSystem: this.vote!.votingSystem
			});
		},
		async evaluateVote() {
			const res = await api.evaluateVote(this.vote!.id);
			this.results = res.results;
		}
	},
	async mounted() {
		this.votingSystems = await api.votingSystems();
		this.vote = await api.vote(this.$route.params.id as string);
	}
});
