

import ace, { require } from "ace-builds";
import { defineComponent } from "vue";
import api, { TokenFile, V1VotingSystemResponse, VoteResult } from "@/lib/api";
import FileUpload from "@/components/FileUpload.vue";
import VoteResultRender from "@/components/VoteResultRender.vue";
import Vote from "@/views/Vote.vue";
import { readUploadedFile } from "@/lib/helpers";

ace.config.setModuleUrl("ace/mode/javascript", require("file-loader?esModule=false!ace-builds/src-noconflict/mode-javascript.js"));
ace.config.setModuleUrl("ace/theme/solarized_light", require("file-loader?esModule=false!ace-builds/src-noconflict/theme-solarized_light.js"));

interface UtilityFunctionDefinition {
	prototype: string;
	description: string;
}

interface Model {
	editor: ace.Ace.Editor;
	votingSystem: V1VotingSystemResponse;
	tokenList: TokenFile;
	responseSheetLink: string;
	previewTitle: string;
	results: VoteResult[];
	messages: string[];
	name: string;
	utilityFunctions: UtilityFunctionDefinition[];
}

export default defineComponent({
	name: "VotingSystemEdit",
	components: { Vote, VoteResultRender, FileUpload },
	data(): Partial<Model> {
		return {
			editor: undefined,
			votingSystem: undefined,
			tokenList: undefined,
			results: [],
			responseSheetLink: "",
			messages: [],
			name: "",
			utilityFunctions: [
				{
					prototype: "io.votes() : VoteAnswer[]",
					description: "Get the valid votes. Returns an array containing all valid votes"
				},
				{
					prototype: "io.voteName(): string",
					description: "Get the name of the vote"
				},
				{
					prototype: "io.name(): string",
					description: "Get the title for the question of a single question vote"
				},
				{
					prototype: "io.result(result: VoteResult) : void\n" +
						"export type VoteResult = VoteResultCommons & \n" +
						"    (TextResult | ScalarResult | ListResult | MapResult | GroupedMapResult);\n" +
						"\n" +
						"export interface VoteResultCommons {\n" +
						"\ttitle: string;\n" +
						"}\n" +
						"\n" +
						"export interface TextResult {\n" +
						"\ttype: \"text\",\n" +
						"\tmessage: string;\n" +
						"}\n" +
						"\n" +
						"export interface ScalarResult {\n" +
						"\ttype: \"scalar\",\n" +
						"\tvalue: number;\n" +
						"}\n" +
						"\n" +
						"export interface MapResult {\n" +
						"\ttype: \"map\",\n" +
						"\tvalues: { [Key: string]: number };\n" +
						"}\n" +
						"\n" +
						"export interface ListResult {\n" +
						"\ttype: \"list\";\n" +
						"\titems: string[];\n" +
						"}\n" +
						"\n" +
						"export interface GroupedMapResult {\n" +
						"\ttype: \"groupedMap\";\n" +
						"\tvalues: { [Group: string]: { [Key: string]: number } };\n" +
						"}\n",
					description: "Submit result data."
				},
				{ prototype: "io.log(obj: any) : void", description: "Log a message or an object" },
				{
					prototype: "tools.extractBrackets(answer: VoteAnswer): VoteAnswer",
					description: "Remove everything not in brackets from the vote value keys. Returns the same votes but with the text in the brackets as keys"
				}
			]
		};
	},
	async mounted() {

		this.votingSystem = await api.votingSystem(this.$route.params.id as string);
		this.editor = ace.edit(this.$refs.editor as HTMLElement, {
			useWorker: false,
			mode: "ace/mode/javascript",
			theme: "ace/theme/solarized_light",
			value: this.votingSystem.code,
			minLines: 25,
			maxLines: 25,
			fontFamily: "Inconsolata",
			fontSize: 18
		});
		this.name = this.votingSystem.name;
	},

	methods: {

		async testFile(file: File): Promise<void> {
			this.tokenList = await readUploadedFile(file);
		},

		async updatePreview(): Promise<void> {
			const testResults = await api.votingSystemTest(this.votingSystem!.id, {
				tokens: this.tokenList!,
				responseSheetLink: this.responseSheetLink!,
				code: this.editor!.getValue()
			});

			this.results = testResults.results;
		},

		async save(): Promise<void> {
			this.votingSystem = await api.votingSystemUpdate(this.votingSystem!.id, {
				code: this.editor!.getValue(),
				name: this.name!
			});
		}
	}
});
