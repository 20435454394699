import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "mt-3 content" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { class: "columns" }
const _hoisted_4 = {
  class: "column is-half",
  ref: "parent"
}
const _hoisted_5 = { ref: "canvas" }
const _hoisted_6 = {
  key: 1,
  class: "content"
}
const _hoisted_7 = {
  key: 2,
  class: "content"
}
const _hoisted_8 = { class: "columns" }
const _hoisted_9 = { class: "column is-four-fifths" }
const _hoisted_10 = { class: "column" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h4", null, _toDisplayString(_ctx.title), 1),
    (['map', 'groupedMap'].includes(_ctx.result.type))
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("canvas", _hoisted_5, null, 512)
            ], 512)
          ])
        ]))
      : (_ctx.result.type === 'list')
        ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
            _createElementVNode("ul", null, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.result.items, (item) => {
                return (_openBlock(), _createElementBlock("li", null, _toDisplayString(item), 1))
              }), 256))
            ])
          ]))
        : (['text', 'scalar'].includes(_ctx.result.type))
          ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
              _createElementVNode("div", _hoisted_8, [
                _createElementVNode("div", _hoisted_9, [
                  _createElementVNode("pre", null, _toDisplayString(_ctx.result.message), 1)
                ]),
                _createElementVNode("div", _hoisted_10, [
                  _createElementVNode("button", {
                    type: "button",
                    class: "button is-link",
                    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.copy(_ctx.result.message)))
                  }, "Copy")
                ])
              ])
            ]))
          : _createCommentVNode("", true)
  ]))
}