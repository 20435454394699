import debug from "debug";
import axios, { AxiosInstance } from "axios";

export interface MeResponse {
	email: string;
}

export interface V1VotingSystemsResponseVotingSystem {
	name: string;
	id: string;
}

export interface CreateVoteRequest {
	name: string;
	votingSystem: string;
	prefilledLink: string;
	responseSheetLink: string;
}

export interface V1VoteResponse {
	id: string;
	name: string;
	votingSystem: string,
	prefilledLink: string;
	spreadsheetLink: string;
	tokens: string[];
}

export type V1VotesResponse = Omit<V1VoteResponse, "tokens">[];

export interface V1VotingSystemResponse extends V1VotingSystemsResponseVotingSystem {
	code: string;
}

export type V1VotingSystemsResponse = V1VotingSystemsResponseVotingSystem[];

export interface V1EventResponse {
	id: string;
	name: string;
}

export type V1EventsResponse = V1EventResponse[];

export type TokenFile = string[];

export interface VoteResultCommons {
	title: string;
}

export interface TextResult {
	type: "text",
	message: string;
}

export interface ScalarResult {
	type: "scalar",
	value: number;
}

export interface MapResult {
	type: "map",
	values: { [Key: string]: number };
}

export interface ListResult {
	type: "list";
	items: string[];
}

export interface GroupedMapResult {
	type: "groupedMap";
	values: { [Group: string]: { [Key: string]: number } };
}

export type VoteResult =
	VoteResultCommons & (
	TextResult | ScalarResult | ListResult | MapResult | GroupedMapResult
	);


export interface V1VotingSystemTestResponse {
	results: VoteResult[];
}

export interface V1VotingSystemTestRequest {
	tokens: TokenFile;
	code: string;
	responseSheetLink: string;
}

export interface V1VotingSystemUpdateRequest {
	code: string;
	name: string;
}

export interface V1UpdateVoteRequest {
	name: string;
	prefilledLink: string;
	spreadsheetLink: string;
	votingSystem: string;
	tokens: TokenFile;
}

export interface V1EvaluateVoteResponse {
	results: VoteResult[];
}

export interface Api {
	me(): Promise<MeResponse>;

	votes(eventId: string): Promise<V1VotesResponse>;

	votingSystems(): Promise<V1VotingSystemsResponse>;

	votingSystem(id: string): Promise<V1VotingSystemResponse>;

	createVote(newVote: CreateVoteRequest, eventId: string): Promise<V1VoteResponse>;

	vote(id: string): Promise<V1VoteResponse>;

	updateVote(id: string, request: V1UpdateVoteRequest): Promise<V1VoteResponse>;

	deleteVote(id: string): Promise<void>;

	evaluateVote(id: string): Promise<V1EvaluateVoteResponse>;

	recentEvent(): Promise<V1EventResponse>;

	votingSystemTest(votingSystemId: string, testParameters: V1VotingSystemTestRequest): Promise<V1VotingSystemTestResponse>;

	votingSystemUpdate(votingSystemId: string, request: V1VotingSystemUpdateRequest): Promise<V1VotingSystemResponse>;

	votingSystemCreate(): Promise<V1VotingSystemResponse>;

	events(): Promise<V1EventsResponse>;
}

const verbose = debug("app:api");

class ApiImpl implements Api {
	private axiosInstance: AxiosInstance;

	constructor() {
		this.axiosInstance = axios.create({
			baseURL: "/api",
			responseType: "json",
			maxRedirects: 0
		});

		this.axiosInstance.interceptors.request.use((value) => {
			verbose(`> ${value.method?.toUpperCase()} ${value.url}`);
			return value;
		});

		this.axiosInstance.interceptors.response.use(
			(value) => {
				verbose(
					`< ${value.status} ${
						value.statusText
					} (${value.config.method?.toUpperCase()} ${
						value.config.url
					})`
				);
				return value;
			},
			(error) => {
				if (error.name === "Network Error")
					location.href = "/login";

				const config = error.config!;
				verbose(
					`< ${error.name}: ${
						error.message
					} (${config.method?.toUpperCase()} ${config.url})`
				);
				throw error;
			}
		);
	}

	me(): Promise<MeResponse> {
		return this.axiosInstance.get<MeResponse>("/v1/me").then(v => v.data);
	}

	recentEvent(): Promise<V1EventResponse> {
		return this.axiosInstance.get<V1EventResponse>("/v1/recent-event").then(value => value.data);
	}

	votes(event: string): Promise<V1VotesResponse> {
		return this.axiosInstance.get<V1VotesResponse>("/v1/votes", { params: { eventId: event } }).then(value => value.data);
	}

	vote(id: string): Promise<V1VoteResponse> {
		return this.axiosInstance.get<V1VoteResponse>(`/v1/vote/${id}`).then(value => value.data);
	}

	updateVote(id: string, request: V1UpdateVoteRequest): Promise<V1VoteResponse> {
		return this.axiosInstance.put<V1VoteResponse>(`/v1/vote/${id}`, request).then(value => value.data);
	}

	deleteVote(id: string): Promise<void> {
		return this.axiosInstance.delete(`/v1/vote/${id}`).then(value => undefined);
	}

	votingSystem(id: string): Promise<V1VotingSystemResponse> {
		return this.axiosInstance.get<V1VotingSystemResponse>(`/v1/voting-system/${id}`).then(value => value.data);
	}

	votingSystems(): Promise<V1VotingSystemsResponse> {
		return this.axiosInstance.get<V1VotingSystemsResponse>("/v1/voting-systems").then(value => value.data);
	}

	createVote(newVote: CreateVoteRequest, eventId: string): Promise<V1VoteResponse> {
		return this.axiosInstance.post<V1VoteResponse>("/v1/create-vote", newVote, { params: { eventId } }).then(value => value.data);
	}

	evaluateVote(id: string): Promise<V1EvaluateVoteResponse> {
		return this.axiosInstance.post<V1EvaluateVoteResponse>(`/v1/evaluate-vote/${id}`).then(value => value.data);
	}

	votingSystemTest(votingSystemId: string, testParameters: V1VotingSystemTestRequest): Promise<V1VotingSystemTestResponse> {
		return this.axiosInstance.post<V1VotingSystemTestResponse>(`/v1/voting-system/${votingSystemId}/test`, testParameters).then(value => value.data);
	}

	votingSystemUpdate(votingSystemId: string, request: V1VotingSystemUpdateRequest): Promise<V1VotingSystemResponse> {
		return this.axiosInstance.patch<V1VotingSystemResponse>(`/v1/voting-system/${votingSystemId}`, request).then(value => value.data);
	}

	votingSystemCreate(): Promise<V1VotingSystemResponse> {
		return this.axiosInstance.post<V1VotingSystemResponse>(`/v1/voting-system`).then(value => value.data);
	}

	events(): Promise<V1EventsResponse> {
		return this.axiosInstance.get<V1EventsResponse>(`/v1/events`).then(value => value.data);
	}
}

export default new ApiImpl() as Api;

