
import { defineComponent } from "vue";
import state from "@/lib/state";

export default defineComponent({
	name: "Dashboard",
	data() {
		return {
			eventId: 0
		};
	},
	components: {}
});
