import { TokenFile } from "@/lib/api";

export async function readUploadedFile(file: File): Promise<TokenFile> {
	return new Promise<TokenFile>((resolve, reject) => {

		const fileReader = new FileReader();

		fileReader.onload = ev => {
			const text = fileReader.result as string;
			const split = text.split("\n").map(value => value.trim());
			resolve(split);
		};

		fileReader.readAsText(file, "utf-8");
	});
}