
import { defineComponent } from "vue";
import api, { V1EventsResponse } from "@/lib/api";
import state from "@/lib/state";

export default defineComponent({
	name: "Navigation",
	data() {
		return {
			allEvents: [] as V1EventsResponse,
			email: "",
			links: [
				// { target: "/dashboard", text: "Dashboard" },
				// { target: "/admin", text: "Administration" },
				{ target: "/votes", text: "Votes" },
				{ target: "/voting-systems", text: "Voting Systems" }
			],
			eventId: "",
			showMenu: false
		};
	},
	methods: {
		toggleBurgerMenu() {
			this.showMenu = !this.showMenu;
		},
		updateEventId() {
			state.setEventId(this.eventId);
		}
	},
	async mounted() {
		const meResponse = await api.me();
		this.email = meResponse.email;
		this.allEvents = await api.events();
		this.eventId = await state.getEventId();
	}
});

