

import { defineComponent } from "vue";
import { BarController, BarElement, CategoryScale, Chart, LinearScale } from "chart.js";
import { GroupedMapResult, MapResult, VoteResult } from "@/lib/api";

Chart.register(LinearScale, BarController, BarElement, CategoryScale);

export default defineComponent({
	name: "VoteResultRender",
	props: {
		result: Object
	},
	data() {
		return {
			title: ""
		};
	},
	methods: {
		copy(text: string) {
			navigator.clipboard.writeText(text);
		},
		map(result: MapResult) {
			const chart = new Chart(this.$refs.canvas as HTMLCanvasElement, {
				type: "bar",
				options: {
					responsive: true,
					scales: {
						y: {
							beginAtZero: true
						}
					}
				},
				data: {
					labels: Object.keys(result.values), //Object.keys(this.results),
					datasets: [{
						data: Object.values(result.values) //Object.values(this.results)
					}]
				}
			});
		},
		groupedMap(result: GroupedMapResult) {

		},
		setCanvasSize() {
			const parent = this.$refs.parent as HTMLDivElement;
			const canvas = this.$refs.canvas as HTMLCanvasElement;

			canvas.width = Math.min(parent.offsetWidth, 768);
			canvas.height = parent.offsetHeight;
		}
	},
	mounted() {
		const result = this.result as VoteResult;

		this.title = result.title;

		switch (result.type) {
			case "map":
				this.map(result);
				break;
			case "groupedMap":
				this.groupedMap(result);
				break;
			default:
				break;
		}

	}
});

