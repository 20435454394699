
import { defineComponent } from "vue";
import api, { V1VotesResponse, V1VotingSystemsResponse } from "@/lib/api";
import state from "@/lib/state";

export default defineComponent({
	name: "Votes",
	data(): { votes: V1VotesResponse, votingSystems: V1VotingSystemsResponse, handlerId: number } {
		return {
			votes: [],
			votingSystems: [],
			handlerId: 0
		};
	},
	methods: {
		async deleteVote(voteId: string) {
			await api.deleteVote(voteId);
			this.votes = await api.votes(await state.getEventId())
				.then(value => value.sort((a, b) => a.name.localeCompare(b.name)));
		},
		async updateVotes() {
			this.votes = await api.votes(await state.getEventId())
				.then(value => value.sort((a, b) => a.name.localeCompare(b.name)));
			this.votingSystems = await api.votingSystems();
		}
	},
	async mounted() {
		this.handlerId = state.onEventChange(newId => this.updateVotes());
		await this.updateVotes();
	},
	beforeUnmount() {
		state.offEventChange(this.handlerId);
	}
});
