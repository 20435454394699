import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import Votes from "@/views/Votes.vue";
import Admin from "@/views/Admin.vue";
import VotingSystems from "@/views/VotingSystems.vue";
import Vote from "@/views/Vote.vue";
import Dashboard from "@/views/Dashboard.vue";
import VotingSystemEdit from "@/views/VotingSystemEdit.vue";
import CreateVote from "@/views/CreateVote.vue";

const routes: Array<RouteRecordRaw> = [
	{ path: "/dashboard", name: "Dashboard", component: Dashboard },
	{ path: "/votes", name: "Votes", component: Votes },
	{ path: "/votes/create", name: "CreateVote", component: CreateVote },
	{ path: "/voting-systems", name: "VotingSystems", component: VotingSystems },
	{ path: "/voting-system/:id", name: "VotingSystemEdit", component: VotingSystemEdit },
	{ path: "/admin", name: "Admin", component: Admin },
	{ path: "/vote/:id", name: "Vote", component: Vote }
];

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes
});

export default router;
