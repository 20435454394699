import { withModifiers as _withModifiers, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["drop-area", _ctx.isDragOver?'drag-over':'']),
    onDrop: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.dropFile && _ctx.dropFile(...args)), ["prevent"])),
    onDragover: _cache[1] || (_cache[1] = _withModifiers(() => {}, ["prevent"])),
    onDragenter: _cache[2] || (_cache[2] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.dragEnter && _ctx.dragEnter(...args)), ["prevent"])),
    onDragleave: _cache[3] || (_cache[3] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.dragLeave && _ctx.dragLeave(...args)), ["prevent"]))
  }, " Drag Token Sheet here ", 34))
}