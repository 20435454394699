
import Navigation from "@/components/Navigation.vue";
import { defineComponent } from "vue";
import Footer from "@/components/Footer.vue";
import api from "@/lib/api";

export default defineComponent({
	components: { Navigation, Footer },
	async mounted() {
		try {
			const me = await api.me();
		} catch (e) {
			location.href = "/login";
		}
	}
});
